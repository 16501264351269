import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";
import { useParams } from "react-router-dom";
import ic_flip from "./Assets/ic_flip_white.png";
import img_back_Creator from "./Assets/Creator.png";
import img_back_Developer from "./Assets/Developer.png";
import img_back_Investor from "./Assets/Investor.png";
import img_back_Landlord from "./Assets/Landlord.png";

export default function FlipCard(props) {
  const [isFlipped, setIsFlipped] = useState(false);
  let { cardId } = useParams();

  const category =
    cardId > 300 && cardId <= 400
      ? "Developer"
      : cardId > 400 && cardId <= 430
      ? "Investor"
      : cardId > 430
      ? "Creator"
      : "Landlord";

  cardId =
    cardId > 300 && cardId <= 400
      ? cardId - 300
      : cardId > 400 && cardId <= 430
      ? cardId - 400
      : cardId > 430
      ? cardId - 430
      : cardId;

  return (
    <div className="App">
      <body>
        <div>
          <ReactCardFlip isFlipped={isFlipped}>
            <div className="h-screen flex items-center justify-center">
              <div className="flex h-fit items-center justify-center w-fit group relative">
                <img
                  style={{
                    minHeight: "15rem",
                    maxHeight: "40rem",
                    height: "100vh",
                  }}
                  src={`https://proprt.mypinata.cloud/ipfs/QmQ7t5gUo2D8mCB45fh4ipEbR5STBBe3hckQRxcNTZGn7e/${category}/${cardId}.png`}
                  alt="card_back"
                  // className="text-center mx-auto"
                  className="group-hover:opacity-75 transition-all duration-200 ease-in-out"
                />
                <button
                  onClick={() => setIsFlipped(!isFlipped)}
                  className="absolute rounded-lg top-2 transition-all duration-300 group-hover:top-5"
                >
                  <img
                    src={ic_flip}
                    alt="ic_flip"
                    className=" group-hover:w-10 transition-all duration-300 bg-gray-900 rounded-lg w-5 group-hover:shadow-xl shadow-none group-hover:border-gray-300 group-hover:border-2"
                  />
                </button>
              </div>
            </div>
            <div className="h-screen flex items-center justify-center">
              <div className="flex h-fit items-center justify-center w-fit group relative">
                <img
                  style={{
                    minHeight: "15rem",
                    maxHeight: "40rem",
                    height: "100vh",
                    borderRadius: "4%",
                  }}
                  src={
                    category === "Developer"
                      ? img_back_Developer
                      : category === "Investor"
                      ? img_back_Investor
                      : category === "Creator"
                      ? img_back_Creator
                      : category === "Landlord"
                      ? img_back_Landlord
                      : ""
                  }
                  alt="card_back"
                  className="group-hover:opacity-75 transition-all duration-200 ease-in-out"
                />
                <button
                  onClick={() => setIsFlipped(!isFlipped)}
                  className="absolute rounded-lg bottom-2 transition-all duration-300 group-hover:bottom-5"
                >
                  <img
                    src={ic_flip}
                    alt="ic_flip"
                    className=" group-hover:w-10 transition-all duration-300 bg-gray-900 rounded-lg w-5 group-hover:shadow-xl shadow-none group-hover:border-gray-300 group-hover:border-2"
                  />
                </button>
              </div>
            </div>
          </ReactCardFlip>
        </div>
      </body>
    </div>
  );
}
